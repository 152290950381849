import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { RegisterPassword } from '../domains/register-password';
import { User } from '../domains/user';
import { PasswordServiceInterface } from '../interfaces/password-service.interface';

@Injectable({
  providedIn: 'root',
})
export class PasswordService implements PasswordServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }

  async recoveryByEmail(email: string): Promise<boolean> {
    try {
      const response: HttpResponse<any> = await lastValueFrom(
        this.http.post(
          this.urlBase + '/users/recover-password',
          { email: email },
          {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response',
          }
        )
      );
      return response.status === 200 ? true : false;
    } catch (error: any) {
      if (error.status === 200) {
        return true;
      }
      if (error.error.message) {
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
        return false;
      }
      throw new Error();
    }
  }

  async changePassword(registerPassword: RegisterPassword): Promise<void> {
    const url = `${this.urlBase}/users/register-new-password`;
    try {
      await lastValueFrom(
        this.http.post<void>(
          url,
          registerPassword,
          { observe: 'response' }
        )
      );
    } catch (error: any) {
      if (error.status === 401) {
        this.toastComponent.showWarningCustomMessage(
          'Link de cadastro de senha expirado!',
          ''
        );
      } else if (error.error.message) {
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
      }
      throw new Error();
    }
  }

  async changeNewPassword(
    password: string
  ): Promise<boolean> {
    const url = `${this.urlBase}/users/new-password`;

    try {
      const requestBody = {
        password: password
      };

      const response: HttpResponse<any> = await lastValueFrom(
        this.http.post<any>(url, requestBody)
      );

      return response.status === 200 ? true : false;
    } catch (error: any) {
      if (error.status === 200) {
        return true;
      }
      if (error.status === 401) {
        this.toastComponent.showWarningCustomMessage(
          'Link de cadastro de senha expirado!',
          ''
        );
        return false;
      } else if (error.error.message) {
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
        return false;
      }
      throw new Error();
    }
  }

  async validateHash (hash: string): Promise<boolean> {
    try {
      return await lastValueFrom(this.http.get<boolean>(this.urlBase + `/users/validate-hash/${hash}`));
    } catch (error: any) {
      if (error.error.message) {
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
        return false;
      }
      throw new Error();
    }
  }

}
