import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import {
  EMPTY as GO_TO_ON_COMPLETE,
  Observable,
  catchError,
  lastValueFrom,
  map,
  of,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { Beneficiary } from '../domains/beneficiary/beneficiary';

import { BeneficiaryServiceInterface } from '../interfaces/beneficiary-service.interface';
import { BeneficiaryClientResumedDTO, BeneficiaryResumedDTO } from '../domains/beneficiary/beneficiary-resumed';
import { BeneficiaryPage, BeneficiarySearchResponseDTO } from '../domains/beneficiary/beneficiary-search-response';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryService implements BeneficiaryServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }



  async getAll(filter?: Object): Promise<Array<Beneficiary>> {
    try {
      const params = new HttpParams({ fromObject: { ...(filter as any) } });
      const beneficiaries: Array<Beneficiary> = await lastValueFrom(
        this.http.get<Array<Beneficiary>>(this.urlBase + `/beneficiaries`, {
          params,
        })
      );

      if (beneficiaries) {
        return beneficiaries;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getFilteredBeneficiaryByClient({
    filter,
    pageSize,
    page,
    clientId
  }: any): Promise<BeneficiaryPage> {
    try {
      const beneficiaryArray: any = await lastValueFrom(
        this.http.get<Array<BeneficiarySearchResponseDTO>>(this.urlBase + `/beneficiaries/list`, {
          params: {
            filter: filter,
            pageSize: pageSize,
            page: page,
            clientId: clientId
          },
        })
      );

      if (beneficiaryArray) {
        return beneficiaryArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }


  async getNamesByClient(id: number): Promise<Array<BeneficiaryResumedDTO>> {
    try {
      const beneficiaries: Array<BeneficiaryResumedDTO> = await lastValueFrom(
        this.http.get<Array<BeneficiaryResumedDTO>>(
          this.urlBase + `/beneficiaries/names/by-client?idClient=` + id
        )
      );

      if (beneficiaries) {
        return beneficiaries;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async save(beneficiary: Beneficiary, image?: File): Promise<boolean> {
    const formData = new FormData();

    formData.append(
      'beneficiary',
      new Blob([JSON.stringify(beneficiary)], { type: 'application/json' })
    );

    if (image) {
      formData.append('image', image, image.name);
    }

    return await lastValueFrom(
      this.http
        .post<boolean>(this.urlBase + `/beneficiaries`, formData, {
          observe: 'response',
        })
        .pipe(
          catchError((error) => {
            this.toastComponent.showApiError(error);
            return of(false);
          }),
          map((value) => !!value)
        )
    );
  }

  async findById(id: number): Promise<Beneficiary> {
    try {
      const beneficiary = await lastValueFrom(
        this.http.get<Beneficiary>(this.urlBase + `/beneficiaries/${id}`));
      return beneficiary;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  update(beneficiary: Beneficiary, image?: File): Observable<boolean> {
    const formData = new FormData();
    formData.append(
      'beneficiary',
      new Blob([JSON.stringify(beneficiary)], { type: 'application/json' })
    );

    if (image) {
      formData.append('image', image, image.name);
    }

    return this.http
      .put(this.urlBase + '/beneficiaries', formData, { observe: 'response' })
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return GO_TO_ON_COMPLETE;
        }),
        map(() => true)
      );
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/beneficiaries/${id}`, {
          observe: 'response',
        })
      );

      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
    return false;
  }

  async checkSubscription(
    productId: number,
    clientId: number
  ): Promise<boolean> {
    try {
      const url = `${this.urlBase}/subscriptions/exists?productId=${productId}&clientId=${clientId}`;

      return await lastValueFrom(this.http.get<boolean>(url));
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error('Erro');
    }
  }



  async changeSituation(id: number, situation: boolean): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('situation', situation);

    try {
      await lastValueFrom(this.http.put(this.urlBase + `/beneficiaries/change-situation/${id}`, params));
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async changeSituationByGroupIds(ids: number[], situation: boolean): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('situation', situation);

    try {
      await lastValueFrom(this.http.put(this.urlBase + `/beneficiaries/change-situation?ids=${ids.join(',')}`, params));
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async deleteBeneficiariesByGroupId(ids: number[]): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/beneficiaries?ids=${ids.join(',')}`, {
          observe: 'response',
        })
      );

      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
    return false;
  }

  async resetPasswords(ids: number[]): Promise<boolean> {
    try {
      await lastValueFrom(this.http.post(this.urlBase + `/beneficiaries/reset-password?userIds=${ids.join(',')}`, null));
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async getByIdClientInAndNameContains(idClients: number[], name: string): Promise<Array<BeneficiaryClientResumedDTO>> {
    let params = new HttpParams();
    params = params.set('idClients', idClients.join(','));
    params = params.set('name', name);

    try {
      let clients = await lastValueFrom(this.http.get<BeneficiaryClientResumedDTO[]>(this.urlBase + `/beneficiaries/by-clients`, {params}));
      return clients;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }
}
