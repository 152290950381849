<mat-form-field class="mat-input" appearance="outline">
   <mat-label class="paragraph paragraph-2 mat-label">Cliente</mat-label>
   <input type="text" placeholder="Buscar Cliente" #text matInput [formControl]="searchClient"
      [matAutocomplete]="auto">
   <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" showPanel="true"
      optionsScroll
      (opened)="openAutoComplete()"
      (closed)="resetProperties()"
      (optionSelected)="clientSelected($event.option.value)"
      (scroll)="onScroll()">
      <mat-option *ngFor="let client of clients" [value]="client">
         {{client.name}}
      </mat-option>
      <mat-option *ngIf="clients.length === 0" [disabled]="true" [value]="null">
         Nenhum cliente encontrado.
      </mat-option>
      </mat-autocomplete>
</mat-form-field>