<div class="container-fluid">
  <div style="margin: 20px 10px 10px 10px">
    <div class="row" *ngIf="tableTitle">
      <div class="col-9">
        <label [ngClass]="titleCss">{{ tableTitle }}</label>
      </div>
      <div class="col-3 d-flex justify-content-end" *ngIf="showAddButton">
        <button mat-mini-fab class="btn btn-primary subtitle subtitle-1 semibold white d-sm-none"
          (click)="onAddButtonClick.emit()" [disabled]="
            disableAddButton ||
            (selectConfiguration?.disableAddButtonWhileNotSelect &&
              !selectFormControl.value)
          ">
          <mat-icon class="icon-btn ml-0 mt-1">add</mat-icon>
        </button>
      </div>
    </div>

    <div class="row mt-3 align-items-center"
      *ngIf="showSearchComponent || selectConfiguration || showAddButton">

      <div class="col-md-4 col-lg-3" *ngIf="selectConfiguration">
        <mat-form-field *ngIf="selectConfiguration.placeholder != 'Cliente'" appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">{{
            selectConfiguration.placeholder
            }}</mat-label>
          <mat-select [formControl]="selectFormControl" [disableOptionCentering]="true"
            panelClass="mat-select-panel-below">
            <mat-option *ngFor="let data of selectConfiguration.data" [value]="data">
              {{ data[selectConfiguration.labelValueName] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-client-auto-complete *ngIf="selectConfiguration.placeholder == 'Cliente'"
          (onSelectClient)="onSelectAutoComplete($event)">
        </app-client-auto-complete>
      </div>

      <div class="col-md-4 col-lg-3" *ngIf="showSearchComponent">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-icon matPrefix class="icon-search">search</mat-icon>
          <mat-label class="paragraph paragraph-2 mat-label">Pesquisar</mat-label>
          <input matInput [formControl]="searchFormControl" />
        </mat-form-field>
      </div>

      <div class="col flex-column flex-md-row justify-content-end" 
        [ngClass]="showMobileVision || !tableTitle ? 'd-flex' : 'd-none d-sm-flex'">
        <div class="d-flex" *ngIf="showAddButton">
          <button mat-button
            class="btn btn-primary subtitle subtitle-1 semibold white" (click)="onAddButtonClick.emit()"
            [disabled]="disableAddButton || disableAllActions || (selectConfiguration?.disableAddButtonWhileNotSelect &&
            !selectFormControl.value)">
            <mat-icon class="icon-btn" *ngIf="addButtonIcon">{{addButtonIcon}}</mat-icon>{{addButtonLabel}}
          </button>
        </div>

        <div class="d-flex justify-content-end mt-2 mt-md-0 ml-md-2" 
          *ngIf="menuOptionModel.length > 0">
          <app-menu-options [menuOptionModel]="menuOptionModel" [showOptions]="hasselectedItem"></app-menu-options>
        </div>
      </div>
    </div>

    <div class="row mt-sm-3 mt-md-0" [ngClass]="showMobileVision ? 'd-flex' : 'd-none d-sm-flex'">
      <div class="col-12">
        <ng-container>
          <table mat-table (matSortChange)="sortData($event)" matSort [dataSource]="tableSourceData"
            class="mat-elevation-z8" w-100>
            <ng-container *ngFor="let column of columnsConfigurations" [matColumnDef]="column.valueName">
              <ng-container *ngIf="!column.actionsConfiguration && !column.isCheckbox">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                  [ngClass]="column.columnHeaderCssClass ?? 'column-10'">
                  {{ column.columnName }}
                </th>
              </ng-container>
              <ng-container *ngIf="column.actionsConfiguration">
                <th mat-header-cell *matHeaderCellDef [ngClass]="column.columnHeaderCssClass ?? 'column-10'">
                  {{ column.columnName }}
                </th>
              </ng-container>
              <ng-container *ngIf="!column.actionsConfiguration && column.isCheckbox">
                <th mat-header-cell *matHeaderCellDef [ngClass]="column.columnHeaderCssClass ?? 'column-10'">
                  <mat-checkbox [formControl]="checkBoxFormControl"></mat-checkbox>
                </th>
              </ng-container>
              <td class="mt-2 column-category truncate-words" mat-cell *matCellDef="let value; let index = index"
                [class]="column.columnValueCssClass">
                <ng-container *ngIf="
                    !column.isCheckbox &&
                    !column.isArray &&
                    !column.actionsConfiguration &&
                    !column.isTag &&
                    !column.toggleConfiguration &&
                    !column.mask
                  ">
                  <div class="status-container column-category-20">
                    <span *ngIf="column.valueName == 'statusTypeLabel'" class="status"
                      [ngClass]="getStatusClass(value[column.valueName])"></span>
                    <span class="truncate-words"
                      [pTooltip]="value[column.valueName] != '' ? (!column.toggleConfiguration ? value[column.valueName] : '') : ''"
                      tooltipPosition="right">
                      {{ value[column.valueName] }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="
                    !column.isArray &&
                    !column.actionsConfiguration &&
                    !column.isTag &&
                    !column.toggleConfiguration &&
                    column.mask
                  ">
                  <div class="status-container column-category-20">
                    <span class="truncate-words" [pTooltip]="
                    value[column.valueName] != ''
                      ? !column.toggleConfiguration
                        ? value[column.valueName]
                        : ''
                        : ''
                    " tooltipPosition="left">
                    {{ value[column.valueName] | mask : column.mask }}
                    </span>
                  </div>

                </ng-container>
                <ng-container *ngIf="
                    column.isCheckbox &&
                    !column.isArray &&
                    !column.actionsConfiguration &&
                    !column.isTag &&
                    !column.toggleConfiguration &&
                    !column.mask
                  ">
                  <div class="status-container column-category-20">
                    <mat-checkbox [name]="checkboxesNgModelName" [(ngModel)]="value[column.valueName]"
                    (change)="checkBoxChange()">
                  </mat-checkbox>
                  </div>
                </ng-container>
                <ng-container *ngIf="
                    column.isArray &&
                    !column.actionsConfiguration &&
                    column.isTag &&
                    !column.toggleConfiguration &&
                    !column.mask
                  ">
                  <mat-chip-list>
                    <mat-chip *ngFor="let eachValue of value[column.valueName]" class="custom-label-input">
                      <span class="truncate-words"
                        [pTooltip]="value[column.valueName] != '' ? (!column.toggleConfiguration ? value[column.valueName] : '') : ''"
                        tooltipPosition="left">{{ eachValue }}</span>
                    </mat-chip>
                  </mat-chip-list>
                </ng-container>
                <ng-container *ngIf="
                    column.isArray &&
                    !column.actionsConfiguration &&
                    !column.isTag &&
                    !column.toggleConfiguration &&
                    !column.mask
                  ">
                  <div class="status-container">
                    <label class="paragraph paragraph-1 truncate-words mb-0" [pTooltip]="
                        value[column.valueName] != ''
                          ? !column.toggleConfiguration
                            ? value[column.valueName]
                            : ''
                          : ''
                      " tooltipPosition="left">
                      <ng-container *ngFor="
                          let eachValue of value[column.valueName];
                          let last = last
                        ">
                        {{ eachValue }}{{ last ? "" : "," }}&nbsp;
                      </ng-container>
                    </label>
                  </div>
                </ng-container>
                <ng-container *ngIf="
                    !column.isArray &&
                    !column.actionsConfiguration &&
                    column.isTag &&
                    !column.toggleConfiguration &&
                    !column.mask
                  ">
                  <mat-chip-list>
                    <mat-chip class="custom-label-input" [pTooltip]="
                        value[column.valueName] != ''
                          ? !column.toggleConfiguration
                            ? value[column.valueName]
                            : ''
                          : ''
                      " tooltipPosition="left">
                      {{ value[column.valueName] }}
                    </mat-chip>
                  </mat-chip-list>
                </ng-container>
                <ng-container *ngIf="
                    !column.isArray &&
                    !column.actionsConfiguration &&
                    !column.isTag &&
                    column.toggleConfiguration &&
                    !column.mask
                  ">
                  <div class="d-flex align-items-center">
                    <input type="checkbox" name="togle" id="togle" class="switchButton" [disabled]="
                        disableToggleButton ||
                        (column.toggleConfiguration.disable
                          ? value[column.toggleConfiguration.disable]
                          : false)
                      " [(ngModel)]="value[column.toggleConfiguration.valueName]" (click)="
                        !column.toggleConfiguration.removeModal
                          ? confirmChangeSituation(value)
                          : ''
                      " />
                    <label class="paragraph paragraph-1 label-st" [pTooltip]="
                        value[column.valueName] != ''
                          ? !column.toggleConfiguration
                            ? value[column.valueName]
                            : ''
                          : ''
                      " tooltipPosition="left">{{
                      value[column.toggleConfiguration.valueName]
                      ? "Ativo"
                      : "Inativo"
                      }}</label>
                  </div>
                </ng-container>
                <ng-container *ngIf="
                    !column.isArray &&
                    column.actionsConfiguration &&
                    !column.isTag &&
                    !column.toggleConfiguration &&
                    !column.mask
                  ">
                  <ng-container *ngFor="
                  let action of column.actionsConfiguration.extraActions
                ">
                    <ng-container *ngIf="action.booleanValueName && action.iconName != 'gamification'">
                      <button *ngIf="value[action.booleanValueName]" [disabled]="
                      action.disableByBooleanProperty
                        ? value[action.disableByBooleanProperty]
                        : false
                    " mat-icon-button (click)="action.onClick(value, index)" [pTooltip]="action.tooltip"
                        tooltipPosition="top" [style]="action.css">
                        <mat-icon *ngIf="action.iconName" class="icon-secondary-color material-icons-outlined"
                          [ngClass]="{
                        'opacity-50 disabled':
                          action.disableByBooleanProperty
                            ? value[action.disableByBooleanProperty]
                            : false
                      }">{{ action.iconName }}</mat-icon>
                      </button>
                    </ng-container>      
                    <app-gamification-icon *ngIf="action.iconName == 'gamification' && 
                    action.booleanValueName ? value[action.booleanValueName] : false"
                    [tooltipText]="action.tooltip"
                    tooltipPosition="left"
                    [isSmallIcon]="true"></app-gamification-icon>                 
                    <ng-container *ngIf="!action.booleanValueName">
                      <label style="margin-top: 0.5rem" *ngIf="action.countAtIconSide">{{
                        value[action.countAtIconSide] > 0
                        ? value[action.countAtIconSide]
                        : ""
                        }}</label>
                      <button [disabled]="
                      action.disableByBooleanProperty
                        ? value[action.disableByBooleanProperty]
                        : disableAllActions
                        ? disableAllActions
                        : false
                    " mat-icon-button (click)="action.onClick(value, index)" [pTooltip]="action.tooltip"
                        tooltipPosition="top" [style]="action.css">
                        <mat-icon *ngIf="action.iconName" class="material-icons-outlined" [ngClass]="{
                        'opacity-50 disabled':
                          action.disableByBooleanProperty
                            ? value[action.disableByBooleanProperty]
                            : false
                      }" [ngStyle]="{
                      color: 'var(--primary)'
                    }">{{ action.iconName }}</mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>
                  <button *ngIf="column.actionsConfiguration.useEditButton" mat-icon-button
                    (click)="onEditButtonClick.emit(value)" pTooltip="Editar" tooltipPosition="top" [disabled]="
                      disableEditButton ||
                      disableAllActions ||
                      (column.actionsConfiguration.disableEditIcon
                        ? !value[column.actionsConfiguration.disableEditIcon]
                        : false)
                    ">
                    <mat-icon class="material-icons-outlined" [ngClass]="{
                        'icon-disabled':
                        disableEditButton ||
                        disableAllActions ||
                        (column.actionsConfiguration.disableEditIcon
                          ? !value[column.actionsConfiguration.disableEditIcon]
                          : false)
                      }" [ngStyle]="{
                      color: 'var(--primary)'
                    }">edit</mat-icon>
                  </button>
                  <button *ngIf="column.actionsConfiguration.useViewButton" mat-icon-button
                    (click)="onViewButtonClick.emit(value)" pTooltip="Visualizar" tooltipPosition="top" [disabled]="
                      disableViewButton ||
                      disableAllActions ||
                      (column.actionsConfiguration.disableViewIcon
                        ? !value[column.actionsConfiguration.disableViewIcon]
                        : false)
                    ">
                    <mat-icon class="material-icons-outlined" [ngClass]="{
                        'icon-disabled':
                        disableViewButton ||
                        disableAllActions ||
                        (column.actionsConfiguration.disableViewIcon
                          ? !value[column.actionsConfiguration.disableViewIcon]
                          : false)
                      }" [ngStyle]="{
                      color: 'var(--primary)'
                    }">visibility</mat-icon>
                  </button>
                  <button *ngIf="column.actionsConfiguration.useDeleteFeature" mat-icon-button [disabled]="
                      deleteDisableCondition(value['isActive']) ||
                      (column.actionsConfiguration.disableDeleteIcon
                        ? !value[column.actionsConfiguration.disableDeleteIcon]
                        : false) ||
                      disableAllActions
                    " (click)="confirmDelete(value)" pTooltip="Excluir" tooltipPosition="top">
                    <mat-icon class="material-icons-outlined" [ngClass]="{
                        'icon-disabled':
                          deleteDisableCondition(value['isActive']) ||
                          (column.actionsConfiguration.disableDeleteIcon
                            ? !value[
                                column.actionsConfiguration.disableDeleteIcon
                              ]
                            : false) ||
                          disableAllActions
                      }" [ngStyle]="{
                        color: 'var(--primary)'
                      }">delete</mat-icon>
                  </button>
                  <ng-container *ngFor="
                      let action of column.actionsConfiguration.extraActionsEnd
                    ">
                    <ng-container *ngIf="action.booleanValueName">
                      <button *ngIf="value[action.booleanValueName]" [disabled]="
                          action.disableByBooleanProperty
                            ? value[action.disableByBooleanProperty]
                            : false
                        " mat-icon-button (click)="action.onClick(value, index)" [pTooltip]="action.tooltip"
                        tooltipPosition="top" [style]="action.css">
                        <mat-icon *ngIf="action.iconName" class="icon-secondary-color material-icons-outlined"
                          [ngClass]="{
                            'opacity-50 disabled':
                              action.disableByBooleanProperty
                                ? value[action.disableByBooleanProperty]
                                : false
                          }">{{ action.iconName }}</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="!action.booleanValueName">
                      <button [disabled]="
                          action.disableByBooleanProperty
                            ? value[action.disableByBooleanProperty]
                            : disableAllActions
                            ? disableAllActions
                            : false
                        " mat-icon-button (click)="action.onClick(value, index)" [pTooltip]="action.tooltip"
                        tooltipPosition="top" [style]="action.css">
                        <mat-icon *ngIf="action.iconName" class="material-icons-outlined" [ngStyle]="{
                            color:
                              disableAllActions
                                ? 'var(--neutral-100)'
                                : 'var(--primary)'
                          }" [ngClass]="{
                            'opacity-50 disabled':
                              action.disableByBooleanProperty
                                ? value[action.disableByBooleanProperty]
                                : false
                          }">{{ action.iconName }}</mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>
                  <button (click)="
                      column.actionsConfiguration.extraButton.onClick(
                        value,
                        index
                      )
                    " [disabled]="
                      (column.actionsConfiguration.extraButton.disable
                        ? value[column.actionsConfiguration.extraButton.disable]
                        : false) ||
                      (column.actionsConfiguration.extraButton
                        .changeButtonData &&
                        column.actionsConfiguration.extraButton.changeButtonData
                          .disable &&
                        value[
                          column.actionsConfiguration.extraButton
                            .changeButtonData.booleanValueName
                        ]) ||
                      (column.actionsConfiguration.extraButton
                        .changeButtonDataTertiary &&
                        column.actionsConfiguration.extraButton
                          .changeButtonDataTertiary.disable &&
                        value[
                          column.actionsConfiguration.extraButton
                            .changeButtonDataTertiary.booleanValueName
                        ])
                    " *ngIf="
                      column.actionsConfiguration.extraButton &&
                      (column.actionsConfiguration.extraButton.isNotVisible
                        ? !value[
                            column.actionsConfiguration.extraButton.isNotVisible
                          ]
                        : true)
                    " [ngClass]="
                      column.actionsConfiguration.extraButton
                        .changeButtonData &&
                      value[
                        column.actionsConfiguration.extraButton.changeButtonData
                          .booleanValueName
                      ]
                        ? column.actionsConfiguration.extraButton
                            .changeButtonData.css
                        : column.actionsConfiguration.extraButton
                            .changeButtonDataTertiary &&
                          value[
                            column.actionsConfiguration.extraButton
                              .changeButtonDataTertiary.booleanValueName
                          ]
                        ? column.actionsConfiguration.extraButton
                            .changeButtonDataTertiary.css
                        : column.actionsConfiguration.extraButton.css ??
                          'btn btn-primary subtitle subtitle-1 semibold white'
                    ">
                    {{
                    column.actionsConfiguration.extraButton
                    .changeButtonData &&
                    value[
                    column.actionsConfiguration.extraButton.changeButtonData
                    .booleanValueName
                    ]
                    ? column.actionsConfiguration.extraButton
                    .changeButtonData.text
                    : column.actionsConfiguration.extraButton
                    .changeButtonDataTertiary &&
                    value[
                    column.actionsConfiguration.extraButton
                    .changeButtonDataTertiary.booleanValueName
                    ]
                    ? column.actionsConfiguration.extraButton
                    .changeButtonDataTertiary.text
                    : column.actionsConfiguration.extraButton.text
                    }}
                  </button>
                </ng-container>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsIds"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsIds"></tr>
          </table>
          <mat-paginator *ngIf="!disablePagination" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
            [showFirstLastButtons]="true" (page)="onPageChange($event)">
          </mat-paginator>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModalSearchTable *ngIf="openModal"></app-confirm-modal>
