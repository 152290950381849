<section>
  <mat-radio-group class="radio-group" [(ngModel)]="onlyGroups" (change)="unselectAll()">
    <mat-radio-button [value]="false" [disabled]="disabled">Todos os colaboradores</mat-radio-button>
    <mat-radio-button [value]="true" [disabled]="disabled">Apenas grupos</mat-radio-button>
  </mat-radio-group>
</section>
<div class="group-session" *ngIf="onlyGroups">
  <div class="row" style="margin-top: 20px;">
    <div class="col-12">
      <div class="group">
        <mat-checkbox [disabled]="disabled" id="change-all-toggle" [(ngModel)]="allGroups" (change)="changeAllGroups()">
        </mat-checkbox>
        <label for="change-all-toggle" class="paragraph paragraph-2 label-checkbox click"
          (click)="allGroups = !allGroups;changeAllGroups()">Selecionar todos</label>
      </div>
      <div class="groups">
        <div *ngFor="let group of groups" class="group">
          <mat-checkbox [disabled]="disabled" [checked]="group.checked" (change)="onSelectGroup(group)">
          </mat-checkbox>
          <label class="paragraph paragraph-2 label-checkbox click" (click)="onSelectGroup(group)">
            {{ group.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
