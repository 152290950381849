export type AlertUserType =
  | 'CLIENT_USER'
  | 'BENEFICIARY'

export const AlertUserTypes = {
  CLIENT_USER: { label: 'Usuário Cliente', value: 'CLIENT_USER' },
  BENEFICIARY: { label: 'Colaborador', value: 'BENEFICIARY' },
};

export const AlertUserTypesWithLabels = Object.values(AlertUserTypes);
