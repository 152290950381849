import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, debounceTime, EMPTY, filter, map, mergeMap, of, switchMap } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { AddressInfo } from 'src/app/shared/domains/address-info';
import { BeneficiaryAddress } from 'src/app/shared/domains/beneficiary/address';
import { Beneficiary } from 'src/app/shared/domains/beneficiary/beneficiary';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { BeneficiaryService } from 'src/app/shared/services/beneficiary.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { DataImportService } from 'src/app/shared/services/data-import.service';
import { ContactAppsWithLabels } from 'src/app/shared/types/contact-app.type';
import { EducationWithLabels } from 'src/app/shared/types/education.type';
import { EmailTypesWithLabels } from 'src/app/shared/types/email.type';
import { EthnicityWithLabels } from 'src/app/shared/types/ethnicity.type';
import { MaritalStatusesWithLabels } from 'src/app/shared/types/marital-status.type';
import { OriginRegistrationWithLabels, OriginTypes } from 'src/app/shared/types/origin-registration.type';
import { SexWithLabels } from 'src/app/shared/types/sex-type';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { brazilianStates } from 'src/app/shared/utils/global-variables';
import { ValidatorGlobal } from 'src/app/shared/utils/validator-global';
import { DataBridgeService } from '../../../shared/services/data-bridge.service';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss'],
})
export class BeneficiaryComponent implements OnInit {

  sexes = SexWithLabels;
  ethnicities = EthnicityWithLabels;
  educations = EducationWithLabels;
  maritalStatuses = MaritalStatusesWithLabels;
  contactApps = ContactAppsWithLabels;
  preferredEmailTypes = EmailTypesWithLabels;
  states: Array<string> = brazilianStates;
  clients: ClientDTO[] = [];
  routedClientId!: number;
  paginationCard: number = 10;
  beneficiaryEnable = true;
  changeImage: boolean = false;
  canCreate: boolean = false;
  canEdit: boolean = false;
  canResetPass: boolean = false;
  canDelete: boolean = false;
  canChangeSituation: boolean = false;
  searchStateFormControl = new FormControl();
  filteredStates: Array<string> = brazilianStates;
  selectedImage: any;
  image!: File | undefined;
  situation = false;
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;

  readonly masks = {
    phoneMask: '(00) 00000-0000',
    cpfMask: '000.000.000-00',
    cepMask: '00.000-000',
  };
  id = 0;

  origins = OriginRegistrationWithLabels;

  form: FormGroup = this.formBuilder.group({
    imgUri: new FormControl(),
    hasBeenImageDeleted: new FormControl(false),
    idUser: new FormControl(),
    socialNetworks: this.formBuilder.group({
      instagram: new FormControl('', ValidatorGlobal.validateInstagram()),
      facebook: new FormControl('', ValidatorGlobal.validateFacebook()),
      linkedin: new FormControl('', ValidatorGlobal.validateLinkedin()),
    }),
    personalData: this.formBuilder.group({
      id: new FormControl({ value: undefined, disabled: true }),
      origin: new FormControl({ value: 'CADASTRO', disabled: true }),
      code: new FormControl({ value: undefined, disabled: true }),
      idClient: new FormControl(undefined, Validators.required),
      clientName: new FormControl(undefined),
      cpf: new FormControl('', [
        Validators.required,
        ValidatorGlobal.validateCpf(),
      ]),
      employeeEnrollment: new FormControl('', Validators.required),
      maritalStatus: new FormControl('', Validators.required),
      sex: new FormControl('', Validators.required),
      primaryPhone: new FormControl('', Validators.required),
      primaryContactApp: new FormControl(null),
      secondaryPhone: new FormControl(),
      secondaryContactApp: new FormControl(),
      tertiaryContactApp: new FormControl(),
      corporateEmail: new FormControl('', [
        Validators.required,
        ValidatorGlobal.validateEmail(),
      ]),
      personalEmail: new FormControl('', ValidatorGlobal.validateEmail()),
      selectedEmail: new FormControl(
        { value: 'CORPORATE', disabled: true },
        Validators.required
      ),
      birthDate: new FormControl('', Validators.required),
      situation: new FormControl(false),
      role: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      nickName: new FormControl(),
      ethnicity: new FormControl(null, Validators.required),
      education: new FormControl(null, Validators.required),
      nationality: new FormControl(null, Validators.required),
      naturalness: new FormControl(null, Validators.required),
      beneficiaryKey: new FormControl(),
    }),
    residentialAddress: this.formBuilder.group({
      id: new FormControl(),
      cep: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      complement: new FormControl(''),
      district: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
    }),
    deliverAddress: this.formBuilder.group({
      id: new FormControl(),
      cep: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      complement: new FormControl(''),
      district: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      isSameAddressAsResidentialAddress: new FormControl(
        true,
        Validators.required
      ),
    }),
  });

  showSubscriptionModal: boolean = false;
  showSubscriptionsListModal: boolean = false;
  isClientActive = false;
  savedBeneficiarySituation: boolean = false;
  //#endregion Forms

  constructor(
    private formBuilder: FormBuilder,
    private dataBridgeService: DataBridgeService,
    private toastComponent: ToastComponent,
    private service: BeneficiaryService,
    private clientService: ClientService,
    public globalFunctions: GlobalFunctions,
    private route: ActivatedRoute,
    private router: Router,

    private permissionsService: NgxPermissionsService,
    private dataImportService: DataImportService,
    private sanitizer: DomSanitizer
  ) {
    route.queryParams
    .pipe(
      map((params) => params['clientId']),
      switchMap(async (clientId) => {
        if (clientId) {
          const url = this.router.url;
          if (url.includes('new-collaborator')) {
            if (await this.clientService.isClientIntegrationAllowed(Number(clientId))) {
              this.router.navigate(['/collaborators']);
              return null;
            }
          }
          return await this.clientService.get(Number(clientId)).toPromise();
        } else {
          return of(null);
        }
      }),
    )
    .subscribe((client: any) => {
      if (client) {
        const personalData = this.form.get('personalData');
        const clientName = client.tradingName ? client.tradingName : client.corporateName;
        this.isClientActive = client.isActive;
        personalData?.get('clientName')?.setValue(clientName);
        personalData?.get('idClient')?.setValue(client.id);
        personalData?.get('clientName')?.disable();
        this.beneficiaryIsEditable(client.id);
      }
    });



    route.queryParams.pipe(
      map((params) => Number(params['id'])),
      mergeMap((id) => (id ? this.service.findById(id) : of('')))
    ).subscribe((beneficiary) => {
      if (beneficiary) this.setEditMode(beneficiary as Beneficiary);
    });

    this.searchStateFormControl.valueChanges.subscribe((filter) => {
      this.filteredStates = this.states.filter((state) =>
        state.toLowerCase().includes(filter.toLowerCase())
      );
    });
    //#region CEP
    const residentialAddressFormGroup = this.form.get('residentialAddress');
    const cepFormControl = residentialAddressFormGroup?.get('cep');
    const deliverAddressFormGroup = this.form.get('deliverAddress');
    const cepDeliverFormControl = deliverAddressFormGroup?.get('cep');

    cepFormControl?.valueChanges
      .pipe(
        filter((cep) => cep && cep.length === 8),
        debounceTime(400),
        mergeMap((cep) => this.dataBridgeService.getByCEP(cep)),
        catchError(() => {
          cepFormControl?.setErrors({ invalid: true });
          return EMPTY;
        }),
        mergeMap((address) => {
          if (!address || this.isObjectEmpty(address)) {
            return EMPTY;
          } else if (!address?.city && !address?.uf) {
            this.toastComponent.showWarningCustomMessage('CEP Inválido!', '');
            cepFormControl?.setErrors({ invalid: true });
            return EMPTY;
          }
          return of(address);
        })
      )
      .subscribe((address) => {
        this.setAddressData(
          residentialAddressFormGroup as AbstractControl,
          address
        );

        if (
          deliverAddressFormGroup?.get('isSameAddressAsResidentialAddress')
            ?.value
        ) {
          cepDeliverFormControl?.setValue(cepFormControl);
          this.setAddressData(
            deliverAddressFormGroup as AbstractControl,
            address
          );
        }
      });

    cepDeliverFormControl?.valueChanges
      .pipe(
        filter(
          (cep) =>
            cep &&
            cep.length === 8 &&
            !deliverAddressFormGroup?.get('isSameAddressAsResidentialAddress')
              ?.value
        ),
        debounceTime(400),
        mergeMap((cep) => this.dataBridgeService.getByCEP(cep)),
        catchError(() => {
          cepDeliverFormControl?.setErrors({ invalid: true });
          return EMPTY;
        }),
        mergeMap((address) => {
          if (!address) {
            return EMPTY;
          } else if (!address?.city && !address?.uf) {
            this.toastComponent.showWarningCustomMessage('CEP Inválido!', '');
            cepDeliverFormControl?.setErrors({ invalid: true });
            return EMPTY;
          }
          return of(address);
        })
      )
      .subscribe((address) =>
        this.setAddressData(deliverAddressFormGroup as AbstractControl, address)
      );

    residentialAddressFormGroup?.valueChanges.subscribe(() => {
      if (
        deliverAddressFormGroup?.get('isSameAddressAsResidentialAddress')?.value
      ) {
        this.setDeliveryAddress();
      }
    });
    //#endregion CEP

    this.form
      .get('personalData')
      ?.get('situation')
      ?.valueChanges.subscribe((situation) => {
        if (situation && !this.isClientActive) {
          this.toastComponent.showWarningCustomMessage(
            'Colaborador vinculado a cliente inativo. Ativação não permitida!'
          );
          this.form
            .get('personalData')
            ?.get('situation')
            ?.setValue(false, { emitEvent: false });
        }
      });

    //#region Email
    this.form
      .get('personalData')
      ?.get('personalEmail')
      ?.valueChanges.subscribe((email) => {
        if (email != undefined && email != '') {
          if (this.beneficiaryEnable)
            this.form.get('personalData')?.get('selectedEmail')?.enable();
        } else {
          this.form
            .get('personalData')
            ?.get('selectedEmail')
            ?.setValue('CORPORATE');
          if (this.beneficiaryEnable)
            this.form.get('personalData')?.get('selectedEmail')?.disable();
        }
      });
    //#endregion Email

    this.form
      .get('personalData')
      ?.get('secondaryPhone')
      ?.valueChanges.subscribe((secondaryPhone) => {
        if (secondaryPhone == '') {
          this.form
            .get('personalData')
            ?.get('secondaryContactApp')
            ?.setValue(undefined);
          this.form
            .get('personalData')
            ?.get('secondaryContactApp')
            ?.clearValidators();
        }
        this.form
          .get('personalData')
          ?.get('secondaryContactApp')
          ?.updateValueAndValidity({ onlySelf: true });
      });

  }

  isObjectEmpty(obj: any) {
    for (let key in obj) {
      if (obj[key] !== null) {
        return false;
      }
    }
    return true;
  }

  async beneficiaryIsEditable(clientId: number) {
    const result = await this.clientService.beneficiariesIsEditable(clientId);
    if (!result) {
      this.form.get('personalData')?.disable();
      this.form.get('complementaryData')?.disable();
      this.form.get('residentialAddress')?.disable();
      if (this.form.get('personalData')?.get('origin')?.value === OriginTypes.IMPORT.value || this.form.get('personalData.origin')?.value === OriginTypes.INTEGRATION.value) {
        this.form.get('personalData')?.get('situation')?.enable()
      }
      this.beneficiaryEnable = result;
    }
  }

  async ngOnInit() {
    this.canCreate = await this.permissionsService.hasPermission(
      ProfilePermissions.CREATE_COLLABORATOR
    );
    this.canEdit = await this.permissionsService.hasPermission(
      ProfilePermissions.UPDATE_COLLABORATOR
    );
    this.canDelete = await this.permissionsService.hasPermission(
      ProfilePermissions.DELETE_COLLABORATOR
    );
    this.canChangeSituation = await this.permissionsService.hasPermission(
      ProfilePermissions.CHANGE_SITUATION_COLLABORATOR
    );
    this.canResetPass = await this.permissionsService.hasPermission(
      ProfilePermissions.RESET_PASSWORD
    );

    if (
      this.route.snapshot.routeConfig?.path?.includes('new-collaborator') &&
      this.canCreate
    ) {
      this.canEdit = true;
    }

    this.setDeliveryAddress();

    if (!this.canChangeSituation)
      this.form.get('personalData.situation')?.disable({ emitEvent: false });
    else this.form.get('personalData.situation')?.enable({ emitEvent: false });
  }

  async setEditMode(beneficiary: Beneficiary) {
    const personalData = this.form.get('personalData');
    const residentialAddress = this.form.get('residentialAddress');
    const deliverAddress = this.form.get('deliverAddress');
    const socialNetworks = this.form.get('socialNetworks');

    const [residential, deliver] = beneficiary.addresses.sort((address) =>
      address.typeAddress == 'RESIDENTIAL' ? -1 : 1
    );

    this.form.get('idUser')?.setValue(beneficiary.idUser);
    this.id = beneficiary.id!;

    await this.getImage(beneficiary.imgUri);

    this.situation = beneficiary.situation;

    personalData?.get('idClient')?.setValue(beneficiary.idClient);
    personalData?.get('id')?.setValue(beneficiary.id);
    personalData?.get('code')?.setValue(beneficiary.code);
    personalData?.get('origin')?.setValue(beneficiary.origin);
    personalData
      ?.get('employeeEnrollment')
      ?.setValue(beneficiary.employeeEnrollment);
    personalData?.get('cpf')?.setValue(beneficiary.cpf);
    personalData
      ?.get('birthDate')
      ?.setValue(new Date(beneficiary.birthDate + 'T00:00'));
    personalData
      ?.get('situation')
      ?.setValue(beneficiary.situation, { emitEvent: false });
    personalData?.get('name')?.setValue(beneficiary.name);
    personalData?.get('lastName')?.setValue(beneficiary.lastName);
    personalData?.get('nickName')?.setValue(beneficiary.nickName);
    personalData?.get('sex')?.setValue(beneficiary.sex);
    personalData?.get('maritalStatus')?.setValue(beneficiary.maritalStatus);
    personalData?.get('role')?.setValue(beneficiary.role);
    personalData?.get('primaryPhone')?.setValue(beneficiary.primaryPhone);
    personalData
      ?.get('primaryContactApp')
      ?.setValue(beneficiary.primaryContactApp);
    personalData?.get('secondaryPhone')?.setValue(beneficiary.secondaryPhone);
    personalData
      ?.get('secondaryContactApp')
      ?.setValue(beneficiary.secondaryContactApp);
    personalData
      ?.get('tertiaryContactApp')
      ?.setValue(beneficiary.tertiaryContactApp);
    personalData?.get('corporateEmail')?.setValue(beneficiary.corporateEmail);
    personalData?.get('personalEmail')?.setValue(beneficiary.personalEmail);
    personalData?.get('selectedEmail')?.setValue(beneficiary.selectedEmail);
    personalData?.get('idUser')?.setValue(beneficiary.idUser);
    personalData?.get('ethnicity')?.setValue(beneficiary.ethnicity);
    personalData?.get('education')?.setValue(beneficiary.education);
    personalData?.get('nationality')?.setValue(beneficiary.nationality);
    personalData?.get('naturalness')?.setValue(beneficiary.naturalness);
    personalData?.get('beneficiaryKey')?.setValue(beneficiary.beneficiaryKey);

    residentialAddress
      ?.get('cep')
      ?.setValue(residential?.cep, { emitEvent: false });
    residentialAddress?.get('street')?.setValue(residential?.streetAddress);
    residentialAddress?.get('number')?.setValue(residential?.number);
    residentialAddress?.get('complement')?.setValue(residential?.complement);
    residentialAddress?.get('district')?.setValue(residential?.district);
    residentialAddress?.get('city')?.setValue(residential?.city);
    residentialAddress?.get('state')?.setValue(residential?.uf);

    deliverAddress
      ?.get('cep')
      ?.setValue(deliver?.cep, { emitEvent: false });
    deliverAddress?.get('street')?.setValue(deliver?.streetAddress);
    deliverAddress?.get('number')?.setValue(deliver?.number);
    deliverAddress?.get('complement')?.setValue(deliver?.complement);
    deliverAddress?.get('district')?.setValue(deliver?.district);
    deliverAddress?.get('city')?.setValue(deliver?.city);
    deliverAddress?.get('state')?.setValue(deliver?.uf);
    deliverAddress
      ?.get('isSameAddressAsResidentialAddress')
      ?.setValue(beneficiary.isSameResidentialAddress);

    socialNetworks?.get('instagram')?.setValue(beneficiary.instagram);
    socialNetworks?.get('facebook')?.setValue(beneficiary.facebook);
    socialNetworks?.get('linkedin')?.setValue(beneficiary.linkedin);

    if (
      this.form.get('deliverAddress.isSameAddressAsResidentialAddress')?.value
    ) {
      this.form.get('deliverAddress')?.disable();
    }

    if (!this.canEdit) {
      this.form.disable({ emitEvent: false });
      // this.typeCategories.disable();
    }

    if (!this.canChangeSituation)
      this.form.get('personalData.situation')?.disable({ emitEvent: false });
    else this.form.get('personalData.situation')?.enable({ emitEvent: false });
  }

  markFormFieldsAsTouched() {
    Object.values(this.form.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach((innerControl) => {
          innerControl.markAsTouched();
        });
      } else {
        control.markAsTouched();
      }
    });
  }

  //#region Save or update
  async saveOrUpdate(): Promise<any> {
    this.markFormFieldsAsTouched();
    if (this.form.valid) {
      const beneficiary = this.mapFormToBeneficiaryDTO();
      if (beneficiary.id) {

        return this.service
          .update(beneficiary, this.image)
          .subscribe((result) => {
            if (result) {
              this.toastComponent.showSuccessCustomMessage(
                'Sucesso!',
                'Colaborador editado com sucesso.',
                3000
              );
              this.router.navigate(['/collaborators']);
            }
          });
      }

      if (await this.service.save(beneficiary, this.image)) {
        this.toastComponent.showSuccessCustomMessage(
          'Sucesso!',
          'Colaborador criado com sucesso.',
          3000
        );
        this.router.navigate(['/collaborators']);
      }
    } else {
      this.toastComponent.showWarningCustomMessage(
        'Campo(s) obrigatório(s) não preenchido(s)!'
      );
    }
  }



  mapFormToBeneficiaryDTO(): Beneficiary {
    const rawValue = this.form.getRawValue();
    const residentialAddress = rawValue.residentialAddress;
    const deliveryAddress = rawValue.deliverAddress;
    const personalData = rawValue.personalData;
    const { instagram, facebook, linkedin } = rawValue.socialNetworks;

    const residentialAddressInfo: BeneficiaryAddress = {
      id: residentialAddress.id,
      beneficiaryId: personalData.id,
      cep: this.globalFunctions.removeMaskCep(residentialAddress.cep),
      streetAddress: residentialAddress.street,
      number: residentialAddress.number,
      complement: residentialAddress.complement,
      district: residentialAddress.district,
      city: residentialAddress.city,
      uf: residentialAddress.state,
      typeAddress: 'RESIDENTIAL',
    };

    const deliveryAddressInfo: BeneficiaryAddress = {
      id: deliveryAddress.id,
      beneficiaryId: personalData.id,
      cep: deliveryAddress.cep.value
        ? this.globalFunctions.removeMaskCep(deliveryAddress.cep.value)
        : this.globalFunctions.removeMaskCep(deliveryAddress.cep),
      streetAddress: deliveryAddress.street,
      number: deliveryAddress.number,
      complement: deliveryAddress.complement,
      district: deliveryAddress.district,
      city: deliveryAddress.city,
      uf: deliveryAddress.state,
      typeAddress: 'DELIVERY',
    };

    const addresses: BeneficiaryAddress[] = [
      residentialAddressInfo,
      deliveryAddressInfo,
    ];

    const beneficiary: Beneficiary = {
      idUser: rawValue.idUser,
      id: personalData.id,
      code: personalData.code,
      addresses,
      birthDate: personalData.birthDate,
      corporateEmail: personalData.corporateEmail,
      cpf: personalData.cpf,
      employeeEnrollment: personalData.employeeEnrollment,
      facebook: this.removeInvisibleChars(facebook),
      sex: personalData.sex,
      idClient: personalData.idClient,
      imgUri: rawValue.imgUri,
      hasBeenImageDeleted: rawValue.hasBeenImageDeleted,
      instagram: this.removeInvisibleChars(instagram),
      isSameResidentialAddress:
        deliveryAddress.isSameAddressAsResidentialAddress,
      lastName: personalData.lastName,
      linkedin: this.removeInvisibleChars(linkedin),
      maritalStatus: personalData.maritalStatus,
      name: personalData.name,
      nickName: personalData.nickName,
      origin: personalData.origin.toUpperCase(),
      personalEmail: personalData.personalEmail,
      primaryContactApp: personalData.primaryContactApp,
      primaryPhone: personalData.primaryPhone,
      role: personalData.role,
      secondaryContactApp: personalData.secondaryContactApp,
      secondaryPhone: personalData.secondaryPhone,
      selectedEmail: personalData.selectedEmail,
      situation: personalData.situation,
      tertiaryContactApp: personalData.tertiaryContactApp,
      ethnicity: personalData.ethnicity,
      nationality: personalData.nationality,
      naturalness: personalData.naturalness,
      education: personalData.education,
      beneficiaryKey: personalData.beneficiaryKey,
    };

    return beneficiary;
  }
  //#endregion Save or update

  confirmDelete() {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este Colaborador?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          try {
            const success = await this.service.delete(
              this.form.get('personalData')?.get('id')?.value
            );
            if (success) {
              this.toastComponent.showSuccessCustomMessage(
                `Colaborador excluído com sucesso`,
                '',
                3000
              );
              this.redirectTo('/collaborators');
            }
          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage(
                'Ops!',
                error.error.message
              );
            }
          }
        }
      });
  }

  //#region Cancelar
  confirmCancel() {
    if (this.form.pristine) {
      this.redirectTo('/collaborators');
    } else {
      this.confirmModal
        ?.showCancelModal('Deseja mesmo cancelar?', '', true)
        .subscribe((isAccepted) => {
          if (isAccepted) {
            this.redirectTo('/collaborators');
          }
        });
    }
  }

  //#endregion Cancelar

  redirectTo(path: string) {
    this.router.navigate([path]);
  }

  //#region Endereços
  setAddressData(addressFormGroup: AbstractControl, address: AddressInfo) {
    addressFormGroup?.get('city')?.patchValue(address.city);
    addressFormGroup?.get('complement')?.patchValue(address.complement);
    addressFormGroup?.get('district')?.patchValue(address.district);
    address.number ? addressFormGroup?.get('number')?.patchValue(address.number) : '';
    addressFormGroup?.get('state')?.patchValue(address.uf);
    addressFormGroup?.get('street')?.patchValue(address.streetAddress);
  }

  setDeliveryAddress(isCheckboxClicked?: boolean) {
    if (!this.canEdit) return;

    if (isCheckboxClicked) {
      this.form
        .get('deliverAddress')
        ?.get('isSameAddressAsResidentialAddress')
        ?.setValue(
          !this.form
            .get('deliverAddress')
            ?.get('isSameAddressAsResidentialAddress')?.value
        );
    }

    const deliverAddressFormGroup = this.form.get('deliverAddress');
    const residentialAddressFormGroup = this.form.get('residentialAddress');

    const cepFormControl = deliverAddressFormGroup?.get('cep');
    const streetFormControl = deliverAddressFormGroup?.get('street');
    const numberFormControl = deliverAddressFormGroup?.get('number');
    const complementFormControl = deliverAddressFormGroup?.get('complement');
    const districtFormControl = deliverAddressFormGroup?.get('district');
    const cityFormControl = deliverAddressFormGroup?.get('city');
    const stateFormControl = deliverAddressFormGroup?.get('state');

    if (
      this.form.get('deliverAddress')?.get('isSameAddressAsResidentialAddress')
        ?.value
    ) {
      cepFormControl?.setValue(residentialAddressFormGroup?.get('cep')?.value);
      cepFormControl?.disable();

      streetFormControl?.setValue(
        residentialAddressFormGroup?.get('street')?.value
      );
      streetFormControl?.disable();

      numberFormControl?.setValue(
        residentialAddressFormGroup?.get('number')?.value
      );
      numberFormControl?.disable();

      complementFormControl?.setValue(
        residentialAddressFormGroup?.get('complement')?.value
      );
      complementFormControl?.disable();

      districtFormControl?.setValue(
        residentialAddressFormGroup?.get('district')?.value
      );
      districtFormControl?.disable();

      cityFormControl?.setValue(
        residentialAddressFormGroup?.get('city')?.value
      );
      cityFormControl?.disable();

      stateFormControl?.setValue(
        residentialAddressFormGroup?.get('state')?.value
      );
      stateFormControl?.disable();
    } else {
      cepFormControl?.setValue('');
      cepFormControl?.enable();

      streetFormControl?.setValue('');
      streetFormControl?.enable();

      numberFormControl?.setValue('');
      numberFormControl?.enable();

      complementFormControl?.setValue('');
      complementFormControl?.enable();

      districtFormControl?.setValue('');
      districtFormControl?.enable();

      cityFormControl?.setValue('');
      cityFormControl?.enable();

      stateFormControl?.setValue('');
      stateFormControl?.enable();
    }
  }
  //#endregion Endereços

  get hasPermissions() {
    if (!this.id && this.canCreate) return true;
    if (this.id && this.canEdit) return true;
    if (this.canChangeSituation) return true;

    return false;
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;
    this.handleFile(file);
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  onDrop(event: any): void {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  handleFile(file: File | null): void {
    if (!file) {
      this.toastComponent.showWarningCustomMessage(
        'Erro!',
        'Nenhum arquivo fornecido.'
      );
      return;
    }
    const validTypes = ['image/png', 'image/jpeg'];
    const maxSize = 15 * 1024 * 1024;
    if (!validTypes.includes(file.type)) {
      this.toastComponent.showWarningCustomMessage(
        'Formato Inválido!',
        'Por favor, selecione um arquivo PNG ou JPG.'
      );
      return;
    }

    if (file.size > maxSize) {
      this.toastComponent.showWarningCustomMessage(
        'Tamaho inválido',
        'Por favor, selecione um arquivo com tamanho máximo de 15mb.'
      );
      return;
    }

    this.removeImg(file);
    this.image = file;
    const reader = new FileReader();
    reader.onload = (e: Event) => {
      const target = e.target as FileReader;
      this.selectedImage = target.result as string;
    };
    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
      this.toastComponent.showWarningCustomMessage(
        'Erro ao ler o arquivo!',
        'Por favor, tente novamente.'
      );
    };
    reader.readAsDataURL(file);
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  downloadImage(imageUrl: string, imageName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  getFileName() {
    const fullName = `${this.form.get('personalData')?.get('name')?.value}_${this.form.get('personalData')?.get('lastName')?.value
      }`;
    return `IMG_${fullName.replace(' ', '_')}`;
  }

  removeImg(fileInput: any) {
    if (this.id && this.form.get('imgUri')?.value) {
      this.form.get('hasBeenImageDeleted')?.setValue(true);
    }
    this.selectedImage = null;
    this.image = undefined;
    this.changeImage = true;
    fileInput.value = '';
  }

  async getImage(imgUrl: string) {
    this.form.get('imgUri')?.setValue(imgUrl);

    if (imgUrl) {
      const blob = await this.dataImportService.getBlob(imgUrl);
      const file = new File([blob], 'img', { type: blob.type });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  isNotValidForm(): boolean {
    return !this.hasPermissions;
  }

  resetPassWord() {
    this.confirmModal?.showModal('Resetar senha', 'Deseja mesmo resetar senha do colaborador ?')
      .subscribe(async isAccepted => {
        if (isAccepted) {
          try {
            const success = await this.service.resetPasswords([this.form.get('idUser')?.value]);
            if (success) {
              this.toastComponent.showSuccessCustomMessage(`Senha resetada com sucesso`, '', 3000);

            }

          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
            }
          }
        }
      });
  }

  removeInvisibleChars(value: string): string {
    return this.globalFunctions.removeInvisibleChars(value);
  }

}
