<app-search-table
  tableTitle="Colaboradores"
  addButtonLabel="Novo Colaborador"
  [tableSourceData]="dataSource"
  [columnsConfigurations]="columns"
  [selectConfiguration]="selectClient"
  (onSelect)="getBeneficiaries($event)"
  [deleteDialogInfo]="dialogInfo"
  [changeSituationDialogInfo]="changeSituationDialogInfo"
  [pageLength]="totalElements"
  [pageIndex]="pageIndex"
  (onAddButtonClick)="redirectToNewBeneficiary()"
  (onFilter)="applyFilter($event)"
  (onPageCount)="onPageChange($event)"
  (onEditButtonClick)="redirectToEditBeneficiary($event)"
  (onViewButtonClick)="redirectToViewBeneficiary($event)"
  (sortDirection)="sortingTable($event)"
  [menuOptionModel]="menuOptions"
  [disableToggleButton]="!canActive"
  [disableAddButton]="!canCreate || clientWithIntegration"
  [disableDeleteButton]="!canDelete"
  [disableEditButton]="!canEdit"
>
</app-search-table>

<div class="container-fluid d-sm-none">
  <div style="margin: 0px 10px 10px 10px">
    <div class="row">
      <div class="col-12">
        <ng-container>
          <div
            class="mobile-session"
            *ngFor="let beneficiary of dataSource.data; let i = index"
          >
            <div
              class="card-row-mobile"
              *ngIf="paginationCard > i"
              (click)="canEdit ? redirectToEditBeneficiary(beneficiary) : redirectToViewBeneficiary(beneficiary)"
            >
              <div class="card-mobile-content">
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 semibold truncate-words">{{ beneficiary.fullName }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">CPF: {{ beneficiary.cpf | mask: '000.000.000-00'
                    }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">Matrícula: {{ beneficiary.employeeEnrollment
                    }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ beneficiary.preferredEmail }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ beneficiary.role }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">Termo
                    {{getResponsabilityTerm(beneficiary.reponsabilityTermStatus)}}
                  </label>
                </div>

              </div>
            </div>
          </div>
          <div
            class="row"
            class="pagination-card"
            *ngIf="dataSource.data.length >= paginationCard"
          >
            <label
              class="paragraph paragraph-2 semibold primary-color"
              (click)="getBeneficiaryMobileVision()"
              >Mostrar mais</label
            >
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModal *ngIf="showConfirmModal"></app-confirm-modal>

<app-groups-modal [groups]="allGroups" [visible]="showModalAssociateGroups" (onClose)="closeModalAssociateGroups();">
</app-groups-modal>
