import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { parse, isValid } from 'date-fns';
import { ptBrCalendar } from '../../utils/global-variables';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-input-data-hour-picker',
  templateUrl: './input-data-hour-picker.component.html',
  styleUrls: ['./input-data-hour-picker.component.scss']
})
export class InputDataHourPickerComponent {
  @Input() initialDate: Date | string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() formInvalid: boolean = false;
  @Input() isOpenInModal: boolean = false;
  @Input() disabled: boolean = false;
  @Output() dateSelected: EventEmitter<string> = new EventEmitter<string>();

  selectedDate!: Date | null;
  dateInput: string = '';
  showCalendar: boolean = false;
  ptBrCalendar = ptBrCalendar;
  previousValue: Date = new Date();

  constructor(
    private config: PrimeNGConfig,
    private eRef: ElementRef
  ) {
    this.config.setTranslation(ptBrCalendar);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    const classNameDataPiker: string = event.srcElement?.className;
    if (this.showCalendar && !this.eRef.nativeElement.contains(event.target)) {
      if(classNameDataPiker.includes("picker")){
        this.showCalendar = true;
      }else {
        this.showCalendar = false;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['initialDate']) {
      if (this.initialDate != null) {
        const newDate = new Date(changes['initialDate'].currentValue);
        this.selectedDate = newDate instanceof Date ? newDate : parse(newDate, 'dd/MM/yyyy HH:mm', new Date());
        this.dateInput = isValid(this.selectedDate) ? this.formatDate(this.selectedDate) : '';
      } else {
        this.selectedDate = null;
        this.dateInput = '';
      }
    }
  }

  onDateInputChange(value: string) {
    const parsedDate = parse(value, 'ddMMyyyyHHmm', new Date());

    if (value.length >= 12) {
      if(isValid(parsedDate)) {
        this.selectedDate = parsedDate;
        this.dateInput = value;
        this.previousValue = parsedDate;
      } else {
        this.selectedDate = null;
        this.dateInput = '';
      }

      this.dateSelected.emit(this.selectedDate ? this.formatDataISO(this.selectedDate) : '');
    } else {
      this.showCalendar = false;
    }
  }

  openCalendar() {
    this.selectedDate = this.initialDate != '' ? new Date(this.initialDate) : new Date();
    this.previousValue = this.selectedDate;
    this.showCalendar = !this.showCalendar;
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  onSelect(event: any) {
    if (this.previousValue.toLocaleDateString() !== event.toLocaleDateString()) {
      this.showCalendar = false;
    }
    if (this.selectedDate) {
      this.dateSelected.emit(this.formatDataISO(this.selectedDate));
      this.previousValue = event;
    }
  }

  formatDataISO(data: Date): string {
    const pad = (num: number) => (num < 10 ? '0' + num : num);

    const ano = data.getFullYear();
    const mes = pad(data.getMonth() + 1);
    const dia = pad(data.getDate());
    const hora = pad(data.getHours());
    const minuto = pad(data.getMinutes());
    const segundo = pad(data.getSeconds());

    return `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}`;
  }
}
