import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { GroupCollaboratorServiceInterface } from '../interfaces/group-collaborator.interface';
import { ClientWithGroups, GroupByClient, GroupRequestDTO, GroupResponseDTO } from '../domains/GroupCollaborator/group-collaborator-dto';

@Injectable({
  providedIn: 'root'
})
export class GroupCollaboratorService implements GroupCollaboratorServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,
  ) {
    this.urlBase = environment.bff_web;
  }

  async getFilteredGroupsByClient({
    clientId,
    pesquisa,
    pageSize,
    page,
  }: any): Promise<GroupResponseDTO[]> {
    try {
      const groupResponse: any = await lastValueFrom(
        this.http.get<Array<GroupResponseDTO[]>>(this.urlBase + `/groups`, {
          params: {
            clientId: clientId,
            pesquisa: pesquisa ? pesquisa : '',
            pageSize: pageSize,
            page: page,
          },
        })
      );

      if (groupResponse) {
        return groupResponse;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async registerGroup(group: GroupRequestDTO): Promise<boolean> {
    try {
      const success = await lastValueFrom(
        this.http.post(this.urlBase + '/groups', group)
      );

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }

    return false;
  }

  async updateGroup(group: GroupRequestDTO): Promise<boolean> {
    try {
      const success = await lastValueFrom(
        this.http.put(this.urlBase + `/groups/${group.id}`, group)
      );

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }

    return false;
  }

  async findById(id: number): Promise<GroupResponseDTO> {
    try {
      const group = await lastValueFrom(
        this.http.get<GroupResponseDTO>(this.urlBase + `/groups/${id}`));
      return group;
    } catch (error) {
      throw new Error();
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/groups/${id}`, {
          observe: 'response',
        })
      );
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }
    return false;
  }
  async changeSituationByGroupIds(ids: number[], situation: boolean): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('situation', situation);

    try {
      await lastValueFrom(this.http.put(this.urlBase + `/groups/change-situation?ids=${ids.join(',')}`, params));
      return true;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }
  async findByBeneficiaryId(id: number): Promise<GroupResponseDTO[]> {
    try {
      const groups = await lastValueFrom(
        this.http.get<GroupResponseDTO[]>(this.urlBase + `/groups/find-by-beneficiary-id/${id}`));
      return groups;
    } catch (error) {
      throw new Error();
    }
  }

  async getGroupsByClientId(clientId: number): Promise<GroupByClient[]> {
    try {
      const groupResponse: GroupByClient[] = await lastValueFrom(this.http.get<GroupByClient[]>(this.urlBase + `/groups/clients/${clientId}`));

      if (groupResponse) {
        return groupResponse;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async findGroupByClient(): Promise<ClientWithGroups[]> {
    try {
      const groupResponse: ClientWithGroups[] = await lastValueFrom(this.http.get<ClientWithGroups[]>(this.urlBase + `/groups/clients`));

      if (groupResponse) {
        return groupResponse;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
