import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CampaignService } from '../../services/campaign/campaign.service';
import { FormControl } from '@angular/forms';
import { CampaignComboboxResponseDTO, CampaignComboboxResponsePage } from '../../domains/campaign';
import { debounceTime, distinctUntilChanged, exhaustMap, filter, map, merge, repeatWhen, scan, Subject, takeUntil, takeWhile } from 'rxjs';

@Component({
  selector: 'app-campaign-select',
  templateUrl: './campaign-select.component.html',
  styleUrls: ['./campaign-select.component.scss'],
})
export class CampaignSelectComponent implements OnInit, OnChanges {

  @Output()
  onSelectCampaign = new EventEmitter<CampaignComboboxResponseDTO | null>();

  @Input()
  selectedCampaignName: string = ''

  @Input()
  disabled?: boolean

  campaigns: CampaignComboboxResponseDTO[] = []
  openFirstAutoComplete = true;
  readonly pageSize: number = 10;
  currentPage: number = 0;
  filter: string = ''
  searchCampaign = new FormControl('');

  private nextPage$ = new Subject<string>();
  private repeatNextPageExecutor$ = new Subject<void>();
  destroyNextPageExecutor$ = new Subject<void>();
  filterChanged$ = new Subject<string>();

  constructor(private campaignService: CampaignService) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.disabled ? this.searchCampaign.disable() : this.searchCampaign.enable()
    }

    if (changes['selectedCampaignName']) {
      this.searchCampaign.setValue(this.selectedCampaignName, { emitEvent: false })
    }
  }

  async ngOnInit() {
    this.searchCampaignValueChanges();
    this.nextPageExecutor();
  }

  async getCampaigns(filter: string, page: number): Promise<CampaignComboboxResponsePage> {
    const result = await this.campaignService.findAll(page, this.pageSize, filter, 'ACTIVE');

    this.campaigns = result.content.map((campaign) => {
      this.currentPage = 1
      return {
        id: campaign.id!,
        name: campaign.name
      }
    })

    return {
      ...result,
      content: this.campaigns
    };
  }

  openAutoComplete(): void {
    if (this.openFirstAutoComplete) {
      this.getCampaigns('', 0);
    }
  }

  resetProperties(): void {
    this.currentPage = !!this.openFirstAutoComplete ? 1 : 0;
    this.filter = '';
    this.destroyAndRepeatNextPageExecutor();
  }

  destroyAndRepeatNextPageExecutor(): void {
    this.destroyNextPageExecutor$.next();
    this.repeatNextPageExecutor$.next();
  }

  onScroll() {
    this.nextPage$.next(this.filter);
  }

  searchCampaignValueChanges(): void {
    this.searchCampaign.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      filter(filter => typeof filter === "string"))
      .subscribe(filter => {
        this.filter = !!filter ? filter : '';
        this.currentPage = 0;
        this.campaigns = [];
        this.destroyAndRepeatNextPageExecutor();
        this.filterChanged$.next(this.filter);
      });
  }

  nextPageExecutor(): void {
    merge(this.nextPage$, this.filterChanged$).pipe(
      exhaustMap((filter) => this.getNextPageCampaign(filter)),
      takeWhile(campaigns => campaigns.last === false, true),
      map(campaigns => campaigns.content),
      scan((allCampaigns: CampaignComboboxResponseDTO[], newCampaigns: CampaignComboboxResponseDTO[]) => allCampaigns.concat(newCampaigns), []),
      takeUntil(this.destroyNextPageExecutor$),
      repeatWhen(() => this.repeatNextPageExecutor$),
    ).subscribe((newCampaigns: CampaignComboboxResponseDTO[]) => {
      this.addNewCampaigns(newCampaigns);
      this.openFirstAutoComplete = false;
    });
  }

  async getNextPageCampaign(filter: string): Promise<CampaignComboboxResponsePage> {
    const page = this.currentPage;
    const campaigns = this.getCampaigns(filter, page);
    this.currentPage++;
    return campaigns;
  }

  addNewCampaigns(newCampaigns: CampaignComboboxResponseDTO[]) {
    const existingCampaignIds = new Set(this.campaigns.map(campaign => campaign.id));

    newCampaigns.forEach(newCampaign => {
      if (!existingCampaignIds.has(newCampaign.id)) {
        this.campaigns.push(newCampaign);
      }
    });
  }

  campaignSelected(campaign: CampaignComboboxResponseDTO): void {
    this.searchCampaign.setValue(campaign.name);
    this.onSelectCampaign.emit(campaign);
  }

  clearSelectedCampaign() {
    this.searchCampaign.setValue('');
    this.onSelectCampaign.emit(null);
  }

}
