import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxRolesService } from 'ngx-permissions';
import { Observable, Subject, lastValueFrom, map } from 'rxjs';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { LocalStorageKeys } from 'src/app/shared/enums/storage-keys';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthServiceV2Interface } from '../../interfaces/auth-service-v2.interface';
import { CacheService } from '../cache.service';
import { SharedService } from '../shared.service';
import { User } from '../../domains/user';
import { AuthPersonaResponse, AuthProfile, ProfileKeyType, ProfileType } from '../../domains/auth/auth-persona-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceV2 implements AuthServiceV2Interface {
  urlBaseSecurity: string;
  urlBaseSecurityV2: string;
  urlBaseBff: string;
  updatingToken: boolean = false;

  private tokenUpdatedSubject = new Subject<void>();
  private profileUpdatedSubject = new Subject<void>();

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,
    private rolesService: NgxRolesService,
    private cacheService: CacheService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.urlBaseSecurity = environment.authorization;
    this.urlBaseSecurityV2 = environment.authorizationV2;
    this.urlBaseBff = environment.bff_web;
  }

  async getProfiles(username: string, password: string, keepConnected: boolean = false): Promise<boolean> {
    const requestBody = {
      username,
      password,
    };

    try {
      const response = await lastValueFrom(
        this.http.post<AuthPersonaResponse>(this.urlBaseSecurityV2 + `/authenticate`, requestBody, {
          headers: { 'Content-Type': 'application/json' },
        })
      );

      if (response.tempToken) {
        localStorage.setItem(LocalStorageKeys.WRH_CACHE_TOKEN, response.tempToken);
        //FIXME: Verificar onde será feito o filter de colaborador, se será realmente no front ou no back
        const filteredProfiles = response.profiles.filter((profile) => profile.profile !== ProfileType.COLABORADOR)
        localStorage.setItem(
          LocalStorageKeys.WRH_CACHE_PROFILES,
          JSON.stringify(filteredProfiles)
        );

        if (filteredProfiles.length === 0) {
          this.toastComponent.showWarningCustomMessage('Para um usuário colaborador é necessário logar pelo app');
          return false;
        }

        if (filteredProfiles.length === 1) {
          const profile: AuthProfile = filteredProfiles[0] as AuthProfile;
          this.authLogin(profile, keepConnected);
        }

        return true;
      }
    } catch (error: any) {
      if (error.error.message)
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
    }

    return false;
  }

  refreshToken(): Observable<any> {
    const refreshToken = localStorage.getItem(
      LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN
    );
    return this.http
      .post<any>(this.urlBaseSecurity + `/refreshToken`, { refreshToken: refreshToken })
      .pipe(
        map((response) => {
          localStorage.setItem(
            LocalStorageKeys.WRH_CACHE_TOKEN,
            response.token
          );
          localStorage.setItem(
            LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN,
            response.refreshToken
          );
          return response.token;
        })
      );
  }

  get tokenUpdated(): Observable<void> {
    return this.tokenUpdatedSubject.asObservable();
  }

  get profileUpdated(): Observable<void> {
    return this.profileUpdatedSubject.asObservable();
  }

  async getUser(): Promise<boolean> {
    const cacheProfileSelected = JSON.parse(localStorage.getItem(LocalStorageKeys.WRH_CACHE_PROFILE_SELECTED)!)?.profile;
    try {
      const response = await lastValueFrom(
        this.http.get<User>(this.urlBaseSecurity + `/userAuthenticated/${cacheProfileSelected}`)
      );

      if (response.id) {
        localStorage.setItem(
          LocalStorageKeys.WRH_CACHE_USER,
          JSON.stringify(response)
        );

        const groupNames = response.groups
          .map((group) => {
            if (group.name == 'AFFETIC') group.name = 'ADMIN'
            return group.name;
          })
          .filter((name) => typeof name !== 'undefined');

        groupNames.forEach((group) => {
          this.rolesService.addRoleWithPermissions(group, response.authorities)
        });

        this.saveGroupsInCache(groupNames);
        this.saveAuthoritiesInCache(response.authorities);

        return true;
      }
    } catch (error) {
      console.log('Error -> auth.service.getUser');
      console.log(error);
    }
    return false;
  }

  async login(clientId: number | null, profile: ProfileKeyType): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http.post<any>(this.urlBaseBff + `/authenticate/token/profile/${profile}`, { clientId }, {
          headers: {
            'Content-Type': 'application/json'
          },
        })
      );

      if (response.token) {
        localStorage.setItem(LocalStorageKeys.WRH_CACHE_TOKEN, response.token);
        localStorage.setItem(
          LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN,
          response.refreshToken
        );
        const selectedProfile = JSON.parse(localStorage.getItem(LocalStorageKeys.WRH_CACHE_PROFILES)!).find((p: AuthProfile) => p.profile === profile)
        localStorage.setItem(
          LocalStorageKeys.WRH_CACHE_PROFILE_SELECTED,
          JSON.stringify(selectedProfile)
        );
        this.profileUpdatedSubject.next();
        return true;
      }
    } catch (error: any) {
      if (error.error.message)
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
    }

    return false;
  }

  getToken(): string | null {
    const token = localStorage.getItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    if (token) {
      return token;
    }
    return null;
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    return token !== null;
  }

  logout(): void {
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_USER);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_KEEP_CONNECTED);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_GROUPS);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_AUTHORITIES);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_PROFILES);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_PROFILE_SELECTED);
  }

  getUserFromCache(): User | null {
    const cacheUser = localStorage.getItem(LocalStorageKeys.WRH_CACHE_USER);
    if (cacheUser) {
      return JSON.parse(cacheUser);
    }
    return null;
  }

  async checkValidLogin(login: string, password: string): Promise<boolean> {
    const requestBody = {
      login,
      password,
    };

    try {
      const response = await lastValueFrom(
        this.http.post<any>(this.urlBaseSecurity + `/authenticate`, requestBody, {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response', // Indica para o Angular observar a resposta completa
        })
      );

      // Verifique diretamente o status na resposta HTTP
      if (response instanceof HttpResponse && response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      // Tratar erro de requisição
      if (error.error.message)
        this.toastComponent.showWarningCustomMessage(
          'A senha atual não confere. Tente novamente.'
        );

      return false;
    }
  }

  saveGroupsInCache(groups: Array<string>) {
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_GROUPS);

    localStorage.setItem(
      LocalStorageKeys.WRH_CACHE_GROUPS,
      JSON.stringify(groups)
    );
  }

  saveAuthoritiesInCache(authorities: string[]) {
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_AUTHORITIES);

    localStorage.setItem(
      LocalStorageKeys.WRH_CACHE_AUTHORITIES,
      JSON.stringify(authorities)
    );

    if (authorities.length == 0) {
      this.toastComponent.showWarningCustomMessage(
        '',
        'Nenhum perfil de acesso encontrado para este usuário.'
      );
    }
  }

  saveUserWithNewUrlAndName(uri: string, name: string) {
    const user = this.getUserFromCache();
    if (user) {
      user.uri = uri;
      user.name = name;
    }
    localStorage.setItem(LocalStorageKeys.WRH_CACHE_USER, JSON.stringify(user));
  }

  async authLogin(profile: AuthProfile, keepConnected: boolean) {
    const successLogin = await this.login(
      profile.clientId,
      profile.profile
    );

    if (successLogin) {
      this.rolesService.flushRolesAndPermissions();
      this.cacheService.refreshShouldPerformAction();
      const successGetUser = await this.getUser();
      this.sharedService.callCarregarRotas();
      if (successGetUser) {
        localStorage.setItem(
          LocalStorageKeys.WRH_CACHE_KEEP_CONNECTED,
          '' + keepConnected
        );
        this.router.navigate(['/home']);
      } else {
        this.toastComponent.showErrorCustomMessage(
          'Ops!',
          'Ocorreu um erro ao trazer os dados do usuário através do token.'
        );
      }
    }
  }

  getLocalProfiles(): AuthProfile[] | null {
    const profiles = localStorage.getItem(LocalStorageKeys.WRH_CACHE_PROFILES);
    if (profiles) return JSON.parse(profiles);
    return null;
  }

  getLocalSelectedProfile(): AuthProfile | null {
    const profile = localStorage.getItem(LocalStorageKeys.WRH_CACHE_PROFILE_SELECTED);
    if (profile) return JSON.parse(profile);
    return null;
  }
}
