<mat-form-field appearance="outline" class="mat-input" [class.mat-form-field-invalid]="required && formInvalid">
    <mat-label>{{label}}{{required ? ' *' : ''}}</mat-label>
    <input pInputText matInput type="text"
           [(ngModel)]="dateInput"
           (ngModelChange)="onDateInputChange($event)"
           mask="00/00/0000 00:00" [disabled]="disabled"/>
    <mat-icon matSuffix class="icon-search click" (click)="openCalendar()">calendar_month</mat-icon>
</mat-form-field>

<mat-card *ngIf="showCalendar && !disabled" class="calendar-flying" [ngClass]="{'calendar-in-modal': isOpenInModal}">
    <div class="relative">
        <mat-icon class="click floating-close" (click)="showCalendar = false">close</mat-icon>
        <p-calendar [showTime]="true" [inline]="true" [(ngModel)]="selectedDate" dateFormat="dd/mm/yy"
        [showOnFocus]="true" (onSelect)="onSelect($event)"></p-calendar>
    </div>
</mat-card>
