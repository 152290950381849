import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastComponent } from '../../components/toaster/toast/toast.component';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { CampaignPage, CampaignRequestDTO, CampaignResponseDTO } from '../../domains/campaign';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = `${environment.bff_web}/campaigns`;
  }
  async findAll(page: number, size: number, search?: string, status?: string, clientId?: number): Promise<CampaignPage> {
    try {
      const eventArray: CampaignPage = await lastValueFrom(
        this.http.get<CampaignPage>(this.urlBase, {
          params: {
            search: search ?? '',
            clientId: clientId ?? '',
            status: status ?? '',
            page: page ?? 0,
            size: size ?? 10
          }
        })
      );

      if (eventArray) {
        return eventArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();

  }

  async findById(id: number): Promise<CampaignResponseDTO> {
    try {
      const response: CampaignResponseDTO = await lastValueFrom(
        this.http.get<CampaignResponseDTO>(`${this.urlBase}/${id}`)
      );
      if (response) {
        return response;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    throw new Error();
  }

  async register(campaign: CampaignRequestDTO): Promise<boolean> {
    try {
      const success = await lastValueFrom(
        this.http.post(this.urlBase, campaign)
      );

      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }

    return false;
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(`${this.urlBase}/${id}`, {
          observe: 'response',
        })
      );
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async edit(id: number, campaign: CampaignRequestDTO): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.http.put(`${this.urlBase}/${id}`, campaign)
      );
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }

  }

  async updateStatus(id: number, status: boolean): Promise<boolean> {
    try {
      const url = `${this.urlBase}/${id}/change-situation`;
      await lastValueFrom(this.http.put<void>(url, null, {
        params: {
          situation: status
        }
      }));
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error('Failed to update status');
    }
  }
}
