import { AbstractControl } from "@angular/forms";
import { Contact } from "../domains/contact";

export class ValidatorGlobal {

  static validateContacts(contactsControl: AbstractControl) {
    const value: Array<Contact> = contactsControl.value;
    return (!value || !value.find(contact => contact.type === 'PRIMARY'))
      ? { invalid: true }
      : null;
  }

  static validateCpf(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const cpf = control.value;
      if(!cpf) return null;
      const isValid = ValidatorGlobal.isCpfValid(cpf);
      return isValid ? null : { cpfInvalid: true };
    };
  }

  static validateEmail(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;
      const isValid = email == undefined || email == null || email == '' ||
      ValidatorGlobal.isEmailValid(email);
      return isValid ? null : { emailInvalid: true };
    };
  }

  static validateInstagram(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const instagramUrl = control.value;
      const isValid = instagramUrl == undefined || instagramUrl == null || instagramUrl == '' ||
      ValidatorGlobal.isInstagramValid(instagramUrl);
      return isValid ? null : { instagramInvalid: true };
    };
  }

  static validateFacebook(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const facebookUrl = control.value;
      const isValid = facebookUrl == undefined || facebookUrl == null || facebookUrl == '' ||
      ValidatorGlobal.isFacebookValid(facebookUrl);
      return isValid ? null : { facebookInvalid: true };
    };
  }

  static validateLinkedin(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const linkedinUrl = control.value;
      const isValid = linkedinUrl == undefined || linkedinUrl == null || linkedinUrl == '' ||
      ValidatorGlobal.isLinkedinValid(linkedinUrl);
      return isValid ? null : { linkedinInvalid: true };
    };
  }

  static noWhitespaceValidator(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const isStartsWithWhitespace = (control.value || '').startsWith(' ');
        return !isStartsWithWhitespace ? null : { fieldInvalid: true };
    };
  }

  static stringZeroOrMin(minSize: number): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const content = control.value;
      const isValid = !content || content.length >= minSize;
      return isValid ? null : { zeroOrMinLengthInvalid: true };
    };
  }

  private static isEmailValid(email: string): boolean {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
  }

  private static isCpfValid(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    let sum = 0;
    let rest;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    return rest === parseInt(cpf.substring(10, 11));
  }

  private static isInstagramValid(user: string): boolean {
    const regex = /(\w*)(:\/\/)*instagram\.com\/(\w*)/;
    return regex.test(user);
  }

  private static isFacebookValid(user: string): boolean {
    const regex = /(\w*)(:\/\/)*facebook\.com\/(\w*)/;
    return regex.test(user);
  }

  private static isLinkedinValid(user: string): boolean {
    const regex = /(\w*)(:\/\/)*linkedin\.com\/(\w*)/;
    return regex.test(user);
  }
}
